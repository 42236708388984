import React from 'react';
import communitycats from "../assets/communitycats.jpg";
import logo from '../assets/logo.gif';
import cover from "../assets/cover2.jpg";
import { Toolbar } from './toolbar';
import { About } from './about';
import { Adopt } from './adopt';
import { Links } from './links';
import { OurPets } from './ourpets';
import { fetchAnimals, negotiate } from "../data/getdata";


export default class HomeCompoent extends React.Component{
  token = null;
  constructor() {
    super();
    this.state = {
      animals: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData(pageNo = 1) {
    if (this.token == null) {
      this.token = await negotiate();
    }
    if (this.token != null) {
      let data = await fetchAnimals(this.token.access_token);
      if (data != null) {
        if (data.status === 401) {
          this.token = null;
          await this.fetchData(pageNo);
          return;
        }
        else {
          let animals = [];
          let pagination = null;
          if (data.animals != null) {
            animals = data.animals;
            pagination = data.pagination;
          }

          if(pageNo===1){
            this.setState(
              {
                ...this.state,
                animals: []
              }
            );
          }

          this.setState(
            {
              ...this.state,
              animals: [
                ...this.state.animals,
                ...animals
              ]
            }
          );

          if(pagination.total_pages>pageNo){
            await this.fetchData(pageNo+1);
            return;
          }
        }
      }
    }
  }

  render(){
      return (
        <React.Fragment>
          <header>
            <nav className="navbar navbar-expand-lg navbar-dark bg-navbar">
              <a className="navbar-brand" href="#">
                <img className="d-none d-lg-block" alt="" src={logo} />
                <img
                  className="d-block d-lg-none toolbar-logo-small"
                  alt=""
                  src={logo}
                />
                <div className="d-none d-lg-block phone-email">
                  njcattitude@gmail.com, 732-491-5696,<br/>
                  PO Box 4732, Highland Park, NJ 08904
                </div>
              </a>
            </nav>
          </header>
          <main>
            <div className="d-none d-lg-block pad-top">
              <br />
              <br />
              <br />
            </div>
            <h5 className="d-none d-lg-block pad-top center"></h5>
            <div className="container-fluid container-plus">
              <div className="row">
                <div className="d-block d-md-none col-xs-12">
                  <div className="center">
                    <div className="bold">
                      njcattitude@gmail.com
                      <br />
                      732-491-5696
                      <br />
                      PO Box 4732, Highland Park, NJ 08904
                    </div>
                    <img src={cover} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="weare501">
                    <div className="text">
                      Cattitude Inc is a 501(c)(3) organization. Donations and
                      contributions are deductable from Federal Income taxes.
                      Gifts, bequests, legacies and transfers made to Cattitude
                      Inc are deductible from Federal estate and gift taxes.
                    </div>
                  </div>
                  <div className="center indent-top">
                    <a
                      target="blank"
                      href="https://www.paypal.com/donate/?hosted_button_id=ZDGDKFNJ56VMJ"
                    >
                      <img alt="" src="https://www.paypal.com/en_US/i/btn/btn_donateCC_LG.gif" />
                    </a>
                  </div>
                </div>
                <br />
                <div className="col-xs-12 col-md-8 col-lg-9 container-plus-inner">
                  <OurPets animals={this.state.animals} />
                  <div className="d-none d-xl-block pad-top">
                    <br />
                    <br />
                    <About />
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 col-lg-3 bg-white center indent-top">
                  <div className="d-block d-lg-none center bold">
                    njcattitude@gmail.com
                    <br />
                    732-491-5696
                    <br />
                    PO Box 4732, 
                    <br />
                    Highland Park, 
                    <br />
                    NJ 08904
                  </div>
                  <div className="d-none d-md-block">
                    <img src={cover} alt="" style={{ width: "100%" }} />
                    <div className="weare501">
                      <div className="text">
                        Cattitude Inc is a 501(c)(3) organization. Donations and
                        contributions are deductable from Federal Income taxes.
                        Gifts, bequests, legacies and transfers made to
                        Cattitude Inc are deductible from Federal estate and
                        gift taxes.
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-md-block center indent-top">
                    <a
                      target="blank"
                      href="https://www.paypal.com/donate/?hosted_button_id=ZDGDKFNJ56VMJ"
                    >
                      <img alt="" src="https://www.paypal.com/en_US/i/btn/btn_donateCC_LG.gif" />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      target="blank"
                      href="https://www.amazon.com/hz/wishlist/ls/2MECE4I916RM6?ref_=wl_share"
                    >
                      <span className="wish">Amazon Wishlist</span>
                    </a>
                  </div>
                  <br/>
                  <div>
                    <a
                      target="blank"
                      href="https://www.cafepress.com/njcattitude"
                    >
                      <img alt="" src="shopstuff.gif" />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      target="blank"
                      href="https://www.adoptapet.com/adoption_rescue/89647-cattitude-inc-highland-park-new-jersey#featured"
                    >
                      <img
                        alt=""
                        style={{ width: "160px" }}
                        src="https://images-ra.adoptapet.com/images/adoptapetlogo2017.svg"
                      />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      target="blank"
                      href="https://www.petfinder.com/search/pets-for-adoption/?shelter_id%5B0%5D=NJ523&sort%5B0%5D=recently_added"
                    >
                      <img
                        alt=""
                        style={{ width: "160px" }}
                        src="https://www.petfinder.com/common/images/info/about-2.gif"
                      />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a target="blank" href="https://rehome.adoptapet.com/">
                      <h2 className="rehome">Rehome</h2>
                    </a>
                  </div>
                  <br />
                  <div>
                    <a target="blank" href="/docs/volunteerapplication.docx">
                      <h2 className="volunteer">Volunteer</h2>
                    </a>
                  </div>
                  <br />
                  <div>
                    <a target="blank" href="/docs/catadoption.docx">
                      <h2 className="adtoption">Adoption Form</h2>
                    </a>
                  </div>
                  <br />
                  <div>
                    <a target="blank" href="https://www.petco.com">
                      <img
                        alt=""
                        style={{ width: "160px" }}
                        src="https://www.petco.com/wcsstore/PetcoStore/img/brand-logo.png"
                      />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      target="blank"
                      href="https://www.igive.com/welcome/lp15/wr35.cfm?c=44953&m=434984"
                    >
                      <img
                        alt=""
                        src="https://www.igive.com/causetoolbox/assets/images/igive120x60.gif"
                      />
                    </a>
                  </div>
                  <br />
                  <div>
                    <a
                      target="blank"
                      href="https://www.gofundme.com/f/happy-go-lucky-surgery-and-expenses"
                    >
                      <img
                        className="icon-gofundme"
                        alt=""
                        src="https://theme.zdassets.com/theme_assets/196447/72ab29b19ac3aa13a964e08152ed35a9e3ae95db.png"
                      />
                    </a>
                  </div>
                  <br />
                  <a
                    target="blank"
                    href="https://www.facebook.com/cattitudeinc"
                  >
                    <i className="fab fa-facebook icon-large" />
                  </a>
                  <br />
                  <div className="d-block d-xl-none">
                    <About />
                  </div>
                  <br />
                  <Links />
                  <br />
                </div>
              </div>
            </div>
            <br />
            <br />
          </main>
          <img className="xd-lg-none pad-top width100" src={communitycats} />
          <div className="xd-block xd-lg-none center footer-small">
            Cattitude Inc has been a proud PetCo Adoption Partner since 2007.
          </div>
        </React.Fragment>
      );
  }
}
